import { IAction } from 'helpers/interfaces';
import { OperatorGamesUtils, localStorageHelper, sort, dataFormatterUtils } from 'utils';
import { PlayerReportTypes } from 'redux/actions/backoffice/player-report-actions';

export interface IPlayerData {
    gameId: number;
    startId: number;
    gameSessionId: number;
    totalBet: number;
    totalWin: number;
    net?: number;
    balance: number;
    playerId: number;
    startBalance?: number;
    endBalance?: number;
    currencyId?: number;
    callback?: Function;
    roundId: string;
}

export interface IPromotionData {
    bet: number;
    expire: Date;
    gameId: number;
    grantedFreeRounds: number;
    promotionId: string;
    remainedFreeRounds: number;
    win: number;
}

export interface IPlayer {
    playerData?: IPlayerData[];
    sortedPlayerData: IPlayerData[];
    currencyId?: number | null;
    promotionData?: IPromotionData;
    campaignsData?: any;
}

export const playerInitialState: IPlayer = {
    sortedPlayerData: [],
    currencyId: null,
    campaignsData: null,
};

const playerReportReducer = (state = playerInitialState, actions: IAction) => {
    switch (actions.type) {
        case PlayerReportTypes.PLAYER_REPORT_REQUEST ||
            PlayerReportTypes.PLAYER_CAMPAIGNS_DATA_REQUEST: {
            return playerInitialState;
        }

        case PlayerReportTypes.PLAYER_REPORT_SUCCESS: {
            const { content, extraInfo } = actions.payload;
            const { playerCurrencyId } = extraInfo;
            const gamesInFilter =
                localStorageHelper.getChainedValue('user.reports.filters.games') || [];

            gamesInFilter.forEach((game: string, i: number) => {
                const idIndex = game.indexOf(' (id:');

                gamesInFilter[i] = idIndex >= 0 ? game.slice(0, idIndex) : game;
            });

            const filterContent = () =>
                content?.filter((item: any) =>
                    gamesInFilter.includes(OperatorGamesUtils.gameIdToName(item.gameId.toString())),
                );

            content.forEach((item: any) => (item.currencyId = playerCurrencyId));

            return {
                playerData: gamesInFilter.length ? filterContent() : content,
                currencyId: playerCurrencyId,
                sortedPlayerData: [],
            };
        }

        case PlayerReportTypes.PLAYER_REPORT_SORT: {
            const { sortState } = actions.payload;

            return {
                ...state,
                sortedPlayerData: sortState
                    ? sort(
                          actions.payload,
                          dataFormatterUtils.getFormattedData(state.playerData, false),
                      )
                    : [],
            };
        }

        case PlayerReportTypes.PROMOTION_DATA_SUCCESS: {
            return {
                ...state,
                promotionData: actions.payload,
            };
        }

        case PlayerReportTypes.PLAYER_CAMPAIGNS_DATA_SUCCESS: {
            return {
                ...state,
                campaignsData: actions.payload,
            };
        }

        default:
            return state;
    }
};

export default playerReportReducer;
