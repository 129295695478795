import * as React from 'react';
import '../commonStyles.scss';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getChecksumReport } from '../../../redux/actions/backoffice/checksum-report-actions';
import {
    getChecksum,
    getTotalChecksumItems,
} from '../../../redux/selectors/backoffice/checksum-selectors';
import { ContentComponent } from '../../../Components/ContentComponent/ContentComponent';
import {
    Button,
    Checkbox,
    Divider,
    Empty,
    GetProp,
    Modal,
    Radio,
    Table,
    TablePaginationConfig,
    TableProps,
} from 'antd';
import { Loader } from '../../../Components/Loader/Loader';
import * as i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { IChecksumData } from '../../../helpers/interfaces';
import { useCallback, useEffect, useState } from 'react';
import { getServerError } from '../../../redux/selectors/backoffice/error-selectors';
import { ErrorComponent } from '../../Errors/ErrorComponent';
import { HeaderTitleComponent } from '../../../Components/ContentComponent/HeaderTitleComponent';
import { ChecksumColumns } from './ChecksumColumns';
import { SettingOutlined } from '@ant-design/icons';
import { getDateOfLastRefresh } from '../../../redux/selectors/user-selectors';
import { dateFormatter } from 'utils';

interface TableParams {
    pagination: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

interface IProps {
    data: IChecksumData[];
    getReport: Function;
    t: i18next.TFunction;
    totalItems: number;
    changePagination: Function;
    pagingInfo: any;
    error: string;
    dateOfLastRefresh: Date;
}

const ChecksumReportComponent = (props: IProps) => {
    const { t } = useTranslation();
    const { data, getReport, totalItems, error, dateOfLastRefresh } = props;
    const columns: any = ChecksumColumns();
    const options = columns.map(({ key }: any) => ({
        label: t(key),
        value: key,
    }));
    const [tableSize, setTableSize] = useState<'small' | 'middle' | 'large'>('middle');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tableHeight, setTableHeight] = useState(0);
    const [checkedList, setCheckedList] = useState(columns.map((item: any) => item.key));
    const visibleColumns = columns.filter((column: any) => checkedList.includes(column.key));
    const totalWidth = visibleColumns.reduce((total: any, col: any) => total + (col.width || 0), 0);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            position: ['topRight'],
            current: 1,
            pageSize: 10,
            total: totalItems,
        },
    });

    const calculateTableHeight = useCallback(() => {
        const height = window.innerHeight * 0.6 - 194;
        setTableHeight(height);
    }, []);

    const toggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    const handleTableChange: TableProps['onChange'] = (pagination) => {
        setTableParams({
            pagination,
        });
        getReport({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sortBy: 'id',
            order: 'ASCENDING',
        });
    };

    useEffect(() => {
        getReport({
            pageNumber: 1,
            pageSize: 10,
            sortBy: 'id',
            order: 'ASCENDING',
        });
    }, [getReport]);

    useEffect(() => {
        setTableParams((prevParams) => ({
            ...prevParams,
            pagination: {
                ...prevParams.pagination,
                total: totalItems,
            },
        }));
    }, [data, totalItems]);

    useEffect(() => {
        calculateTableHeight();
        window.addEventListener('resize', calculateTableHeight);

        return () => window.removeEventListener('resize', calculateTableHeight);
    }, [calculateTableHeight]);

    return (
        <ContentComponent
            header={<HeaderTitleComponent title={t('critical_checksum')} />}
            innerContent={
                error ? (
                    <ErrorComponent error={error} />
                ) : data ? (
                    data.length ? (
                        <div className="casino_performance-table-container">
                            <div className={'casino_performance-table-title'}>
                                <div className="content-wrapper">
                                    <>
                                        <Button
                                            style={{ marginRight: '8px' }}
                                            onClick={toggleModal}
                                        >
                                            <SettingOutlined />
                                        </Button>
                                        Data updated at: {dateFormatter.getFormattedDateOfLastRefresh(dateOfLastRefresh)}
                                    </>
                                </div>
                                <Modal
                                    title="Table tools"
                                    open={isModalVisible}
                                    onOk={toggleModal}
                                    onCancel={toggleModal}
                                >
                                    <Divider>Columns displayed</Divider>
                                    <Checkbox.Group
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            marginBottom: '20px',
                                        }}
                                        options={options}
                                        value={checkedList}
                                        onChange={(checkedValues) => {
                                            setCheckedList(checkedValues.map(String));
                                        }}
                                    />
                                    <Divider>Columns displayed</Divider>
                                    <div style={{ marginBottom: 16 }}>
                                        <span>Change Table Cell Size: </span>
                                        <Radio.Group
                                            value={tableSize}
                                            onChange={(e) => setTableSize(e.target.value)}
                                        >
                                            <Radio.Button value="large">Large</Radio.Button>
                                            <Radio.Button value="middle">Middle</Radio.Button>
                                            <Radio.Button value="small">Small</Radio.Button>
                                        </Radio.Group>
                                    </div>
                                </Modal>
                            </div>
                            <Table
                                style={{
                                    minWidth: '100%',
                                    paddingBottom: '16px',
                                }}
                                className={'casino_performance-table'}
                                virtual
                                dataSource={data}
                                scroll={{ x: totalWidth, y: tableHeight }}
                                columns={visibleColumns}
                                onChange={handleTableChange}
                                pagination={tableParams.pagination}
                                size={tableSize}
                                rowKey={(record) => `${record.fileId.toString()}`}
                                footer={() => <></>}
                            />
                        </div>
                    ) : (
                        <Empty description={t('no_data')} />
                    )
                ) : (
                    <Loader />
                )
            }
        />
    );
};

const mapStateToProps = (state: any) => ({
    data: getChecksum(state),
    totalItems: getTotalChecksumItems(state),
    error: getServerError(state),
    dateOfLastRefresh: getDateOfLastRefresh(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getReport: (data: any) => dispatch(getChecksumReport(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChecksumReportComponent);
