import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import './AddOperatorStyles.scss';
import { STEPS } from './index';
import * as i18next from 'i18next';
import { Dispatch } from 'redux';
import { currencyUtils, localStorageHelper, Messages } from 'utils';
import { Input } from 'antd';
import { UpOutlined } from '@ant-design/icons/lib';
import { addGameConfigurationsRequest } from 'redux/actions/configProvider/add-operator-actions';
import { Footer } from 'Components/Footer/Footer';
import { MainButton, SecondaryButton } from 'Components/CustomButtons/CustomButtons';
import { ChangeEvent } from 'react';

const { TextArea } = Input;

interface IProps {
    previousStep: Function;
    addGameConfigurations: Function;
    t: i18next.TFunction;
}

interface IState {
    configs: any;
    editedConfigs: {};
    collapsedItems: string[];
    invalidFields: string[];
}

class StepEditGameConfigurations extends React.Component<IProps & WithTranslation, IState> {
    state: IState = {
        configs: StepEditGameConfigurations.getConfigurations(),
        editedConfigs: {},
        collapsedItems: StepEditGameConfigurations.getCollapsedItems(),
        invalidFields: [],
    };

    static getConfigurations = () => {
        const currencyIds = localStorageHelper.getChainedValue('user.newOperator.currencyIds');
        const storedConfigurations =
            localStorageHelper.getChainedValue('user.newOperator.configurations') || {};

        return { currencyIds, storedConfigurations };
    };

    static getCollapsedItems = () => {
        const currencyIds = localStorageHelper.getChainedValue('user.newOperator.currencyIds');
        const configs = localStorageHelper.getChainedValue('user.newOperator.configurations') || {};
        const expandedIds = Object.keys(configs);

        return currencyIds.filter((id: string) => expandedIds.indexOf(id.toString()) === -1);
    };

    getConfigs = () => {
        const configsToRender: any[] = [];
        const { collapsedItems, invalidFields, configs } = this.state;
        const { currencyIds, storedConfigurations } = configs;
        const getDefaultValue = (currencyConfig: any) => {
            let string = '';

            Object.keys(currencyConfig).forEach((configId, i) => {
                string += `"${configId}":${currencyConfig[configId]}${
                    Object.keys(currencyConfig).length - 1 > i ? ',\n' : ''
                }`;
            });

            return string;
        };

        currencyUtils.sortCurrencyByCode(currencyIds).forEach((currency: string) => {
            const isCollapsed = collapsedItems.includes(currency);
            const isValid = !invalidFields.includes(currency.toString());
            const { code, id, name } = currencyUtils.getCurrencyDataById(Number(currency));
            const value: string = storedConfigurations?.[currency]
                ? getDefaultValue(storedConfigurations[currency])
                : '';

            configsToRender.push(
                <div className="currency-item" key={currency}>
                    <div className="arrow">
                        <UpOutlined rotate={isCollapsed ? 180 : 0} />
                    </div>
                    <h3 onClick={() => this.handleCollapsing(currency)}>
                        {code} - {name} <span className="gray">{id}</span>
                    </h3>
                    <div className={`currency-content ${isCollapsed ? ' collapsed' : ''}`}>
                        <TextArea
                            defaultValue={value}
                            rows={10}
                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                                this.editConfig(currency, e.target.value);
                            }}
                        />
                        <p className="error">{!isValid && this.props.t('json_not_valid')}</p>
                    </div>
                </div>,
            );
        });

        return <div className="cp-operator-game-configs">{configsToRender}</div>;
    };

    editConfig = (currency: string, value: string) => {
        const { editedConfigs }: any = this.state;

        editedConfigs[currency] = value;

        this.setState({ editedConfigs });
    };

    handleCollapsing = (currency: string) => {
        const { collapsedItems } = this.state;

        if (collapsedItems.includes(currency)) {
            const index = collapsedItems.indexOf(currency);

            if (index > -1) {
                collapsedItems.splice(index, 1);
            }
            this.setState({ collapsedItems: [...collapsedItems] });
        } else {
            this.setState({ collapsedItems: [...collapsedItems, currency] });
        }
    };

    expandAll = () => {
        this.setState({ collapsedItems: [] });
    };

    collapseAll = () => {
        const currencyIds = localStorageHelper.getChainedValue('user.newOperator.currencyIds');

        this.setState({ collapsedItems: currencyIds });
    };

    handleSubmit = () => {
        const invalidFields: string[] = [];
        const operatorId = localStorageHelper.getChainedValue('user.newOperator.id');
        const { editedConfigs }: any = this.state;
        const storedConfigurations: any = {};
        const requestBody: any = {
            defaultConfigIdToGameConfigSettings: {},
            operatorId,
        };
        let isValid = true;

        Object.keys(editedConfigs).forEach((currency: string) => {
            try {
                const parsedConfigs = JSON.parse(`{${editedConfigs[currency]}}`);

                for (const config in parsedConfigs) {
                    if (Object.prototype.hasOwnProperty.call(parsedConfigs, config)) {
                        if (!storedConfigurations[currency]) {
                            storedConfigurations[currency] = {};
                        }
                        storedConfigurations[currency][config] = JSON.stringify(
                            parsedConfigs[config],
                        );
                    }
                }
            } catch (e) {
                Messages.error(
                    this.props.t('json_not_valid') +
                        ': ' +
                        currencyUtils.getCurrencyDataById(Number(currency)).code,
                );
                isValid = false;

                invalidFields.push(currency);
            }
        });

        this.setState({ invalidFields });

        Object.keys(storedConfigurations).forEach((currency: string) => {
            Object.keys(storedConfigurations[currency]).forEach((config: any) => {
                requestBody.defaultConfigIdToGameConfigSettings[config] =
                    storedConfigurations[currency][config];
            });
        });

        if (isValid) {
            this.props.addGameConfigurations({
                requestBody,
                storedConfigurations,
            });
        }
    };

    getBack = () => this.props.previousStep();

    render = () => {
        const { t } = this.props;

        return (
            <>
                <div className="operator-step">
                    <h2 className="title with-paddings">{t(STEPS[3])}</h2>
                    <div className="buttons without-paddings">
                        <div className="btn white" onClick={this.expandAll}>
                            {t('expand_all')}
                        </div>
                        <div className="btn white" onClick={this.collapseAll}>
                            {t('collapse_all')}
                        </div>
                    </div>
                    {this.getConfigs()}
                </div>
                <Footer>
                    <SecondaryButton onClick={this.getBack}>{t('back')}</SecondaryButton>
                    <MainButton onClick={this.handleSubmit}>{t('continue_save')}</MainButton>
                </Footer>
            </>
        );
    };
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    addGameConfigurations: (data: any) => dispatch(addGameConfigurationsRequest(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(StepEditGameConfigurations));
