import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Tag, Tooltip } from 'antd';
import { InfoOutlined } from '@ant-design/icons';

const PromotionsColumns = () => {
    const { t } = useTranslation();

    return [
        {
            title: t('timezone'),
            dataIndex: 'timezone',
            key: 'timezone',
        },
        {
            title: t('startAt'),
            dataIndex: 'startDate',
            key: 'startDate',
            sorter: true,
        },
        {
            title: t('endAt'),
            dataIndex: 'endDate',
            key: 'endDate',
            sorter: true,
        },
        {
            title: t('name'),
            dataIndex: 'name',
            key: 'name',
            sorter: true,
        },
        Table.EXPAND_COLUMN,
        {
            title: t('games'),
            dataIndex: 'games',
            key: 'games',
        },
        {
            title: t('currencyCode'),
            dataIndex: 'currencyCode',
            key: 'currencyCode',
        },
        {
            title: t('status'),
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            render: (text: any, record: any) => {
                const colorToStatus: any = {
                    DISABLED: 'red',
                    ONGOING: 'green',
                    UPCOMING: 'blue',
                    ENDED: 'gold',
                };

                return (
                    <Tag color={colorToStatus[record.status]}>{t(record.status.toLowerCase())}</Tag>
                );
            },
        },
        {
            title: t('strategy'),
            dataIndex: 'strategy',
            key: 'strategy',
        },
        {
            title: t('id'),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: (
                <div style={{ display: 'flex' }}>
                    {t('playing/eligible_player')}{' '}
                    <Tooltip
                        title={t(
                            'for_the_players_list_type_only_playing_players_number_is_reflected',
                        )}
                    >
                        <InfoOutlined />
                    </Tooltip>
                </div>
            ),
            dataIndex: 'eligiblePlayersNumber',
            key: 'eligiblePlayersNumber',
            render: (text: any, record: any) => {
                return `${record.participatingPlayersNumber}${
                    record.strategy !== 'PLAYER_LIST' ? `/${record.eligiblePlayersNumber}` : ''
                }`;
            },
        },
        {
            title: (
                <div style={{ display: 'flex' }}>
                    {t('rounds_played/given')}{' '}
                    <Tooltip
                        title={t(
                            'for_the_players_list_type_only_the_number_of_played_rounds_is_reflected',
                        )}
                    >
                        <InfoOutlined />
                    </Tooltip>
                </div>
            ),
            dataIndex: 'roundsPlayed',
            width: 165,
            key: 'roundsPlayed',
            render: (text: any, record: any) => {
                return `${Number(record.roundsPlayed)}${
                    record.strategy !== 'PLAYER_LIST'
                        ? `/${record.betNumber * record.eligiblePlayersNumber}`
                        : ''
                }`;
            },
        },
        {
            title: t('brandId'),
            dataIndex: 'brandId',
            key: 'brandId',
        },
        {
            title: t('disabledAt'),
            dataIndex: 'disabledAt',
            key: 'disabledAt',
        },
    ];
};

export default PromotionsColumns;
