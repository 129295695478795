import { useTranslation } from 'react-i18next';

export const UserComponentColumns = () => {
    const { t } = useTranslation();

    return [
        {
            title: t('name'),
            dataIndex: 'name',
            key: 'name',
            sorter: (a: any, b: any) => a.name.localeCompare(b.name),
            width: 185,
            fixed: true,
        },
        {
            title: t('login_email'),
            dataIndex: 'email',
            key: 'email',
            sorter: (a: any, b: any) => a.email.localeCompare(b.email),
            width: 165,
            fixed: true,
        },
        {
            title: t('role'),
            dataIndex: 'role',
            key: 'role',
            sorter: (a: any, b: any) => a.role.localeCompare(b.role),
            width: 165,
        },
        {
            title: t('account_status'),
            dataIndex: 'blocked',
            key: 'blocked',
            sorter: (a: any, b: any) => a.blocked - b.blocked,
            render: (blocked: any) => <span>{blocked ? t('blocked') : t('active')}</span>,
            width: 165,
        },
        {
            title: t('actions'),
            dataIndex: 'actions',
            key: 'actions',
            render: (text: any, record: any) => {
                return <span>{record.actions}</span>;
            },
            alignment: 'center',
            width: 165,
        },
    ];
};
