import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { saveAs } from 'file-saver';
import { Button, Modal, Select } from 'antd';
import { Form, Field, ErrorMessage, FormikProvider } from 'formik';
import {
    DownloadOutlined,
    EditOutlined,
    PlusCircleOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import { MainButton, SecondaryButton } from '../../../Components/CustomButtons/CustomButtons';
import { useTranslation } from 'react-i18next';
import { FormikField, FormikInput, FormikSwitch, FormikUpload } from './FormikFields';
import { currencyUtils, localStorageHelper, OperatorGamesUtils } from '../../../utils';
import {
    ICountry,
    ICurrencyData,
    IGameItemData,
    ITimeZoneListItem,
} from '../../../helpers/interfaces';
import { Dispatch } from 'redux';
import {
    createPromotion,
    createPromotionWithCSV,
    editPromotion,
    editPromotionWithCSV,
    getPromotions,
} from '../../../redux/actions/backoffice/promotions-actions';
import { connect } from 'react-redux';
import { validationSchema } from './validationSchema';
import { Overlay } from '../../../Components/Overlay/Overlay';
import { useFormik } from 'formik';
import Papa from 'papaparse';

interface IProps {
    createPromotion: Function;
    editPromotion: Function;
    createPromotionWithCSV: Function;
    editPromotionWithCSV: Function;
    initialData?: any;
}

const getBetSizes = (selectedGame: any, currentCurrency: any) => {
    if (!selectedGame || !currentCurrency) return [];
    const betConfig = selectedGame.betSizes[currentCurrency.id];
    if (!betConfig) return [];
    const parsedConfig = JSON.parse(betConfig);
    return parsedConfig.bets.map((bet: number) => bet * selectedGame.wager);
};

const getInitialValues = (
    initialData: any,
    countriesList: ICountry[],
    gamesList: IGameItemData[],
) => {
    const parsedCsvData: any = initialData?.csv
        ? Papa.parse(initialData.csv, {
              header: false,
              skipEmptyLines: true,
          }).data
        : [];

    const cleanedCsvData =
        parsedCsvData.length > 0 && parsedCsvData[0][0] === 'Player id'
            ? parsedCsvData.slice(1)
            : parsedCsvData;

    const playersCount = cleanedCsvData.length || initialData?.eligiblePlayersNumber || '';

    const newCsvString = Papa.unparse(cleanedCsvData);
    const newCsvBlob = new Blob([newCsvString], { type: 'text/csv' });
    const csvFile =
        cleanedCsvData.length > 0
            ? new File([newCsvBlob], 'imported_list.csv', { type: 'text/csv' })
            : null;

    return {
        campaign_name: initialData?.name || '',
        promotion_type: 'free_rounds',
        timezone: initialData?.timezone || 'Etc/UTC',
        start_at: initialData?.startDate || '',
        end_at: initialData?.endDate || '',
        countries: initialData?.countries
            ? initialData.countries.length === countriesList.length
                ? ['all']
                : initialData.countries
            : ['all'],
        players: playersCount,
        file: csvFile,
        isFile: initialData?.strategy === 'PLAYER_LIST',
        game_name: initialData?.gameIds
            ? initialData.gameIds.length === gamesList.length
                ? ['all']
                : initialData.gameIds.map((gameId: number) =>
                      OperatorGamesUtils.getGameNameByID(gameId),
                  )
            : [],
        currency: initialData?.currencyCode || 'USD',
        bet: initialData?.bet ? Number(initialData.bet) : null,
        expires_in_days: initialData?.expiresInDays || '',
        number_of_free_rounds: initialData?.betNumber || '',
    };
};

const PromotionModal = ({
    createPromotion,
    createPromotionWithCSV,
    editPromotion,
    editPromotionWithCSV,
    initialData,
}: IProps) => {
    const { t } = useTranslation();
    const isMountedRef = useRef(true);
    const [mainModalVisible, setMainModalVisible] = useState(false);
    const [secondartModalVisible, setSecondartModalVisible] = useState(false);
    const [betSizes, setBetSizes] = useState<any>([]);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const currencyList = useMemo(
        () => localStorageHelper.getChainedValue('user.currenciesByBrand'),
        [],
    );
    const [isFile, setIsFile] = useState(initialData?.strategy === 'PLAYER_LIST');
    const countriesList = localStorageHelper.getChainedValue('user.countries');
    const gamesList = useMemo(
        () => OperatorGamesUtils.getGames().filter((game: IGameItemData) => game.freeRoundsEnabled),
        [],
    );
    const timezoneList = localStorageHelper.getChainedValue('user.timezoneList');
    const [filteredGamesList, setFilteredGamesList] = useState([]);

    let initialValues = getInitialValues(initialData, countriesList, gamesList);

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema(t, !!initialData),
        onSubmit: () => {
            showSecondaryModal();
        },
    });

    const { values, setFieldValue } = formik;

    const handleCancel = () => {
        setMainModalVisible(false);
        formik.resetForm();
    };

    const estimatedTotalBets = useCallback(() => {
        const { players, bet, number_of_free_rounds, currency } = formik.values;
        return `${(
            (Number(players) * Number(bet) * Number(number_of_free_rounds)) /
            currencyUtils.getCurrencyDataByCode(currency).subunit
        ).toFixed(2)} ${currency}`;
    }, [formik.values]);

    const estimatedBetsPerPlayer = useCallback(() => {
        const { bet, number_of_free_rounds, currency } = formik.values;
        return `${(
            (Number(bet) * Number(number_of_free_rounds)) /
            currencyUtils.getCurrencyDataByCode(currency).subunit
        ).toFixed(2)} ${currency}`;
    }, [formik.values]);

    const handleCancelSecondary = () => {
        setSecondartModalVisible(false);
        setMainModalVisible(true);
    };

    const showSecondaryModal = () => {
        setMainModalVisible(false);
        setSecondartModalVisible(true);
    };

    const handleSubmit = () => {
        if (Array.isArray(values.countries)) {
            if (values.countries.includes('all')) {
                values.countries = countriesList.map((country: ICountry) => country.alpha2Code);
            } else {
                values.countries = values.countries.map((countryName) => {
                    const foundCountry = countriesList.find(
                        (country: ICountry) => country.name === countryName,
                    );
                    return foundCountry ? foundCountry.alpha2Code : countryName;
                });
            }
        }

        if (Array.isArray(values.game_name)) {
            if (values.game_name.includes('all')) {
                values.game_name = filteredGamesList.map((game: IGameItemData) => game.id);
            } else {
                values.game_name = values.game_name.map((gameName: string) =>
                    OperatorGamesUtils.getGameIdByName(gameName),
                );
            }
        }

        if (isMountedRef.current) {
            if (initialData) {
                values.file
                    ? editPromotionWithCSV({ values, id: initialData.id })
                    : editPromotion({ values, id: initialData.id });
            } else {
                values.file ? createPromotionWithCSV(values) : createPromotion(values);
            }

            setSecondartModalVisible(false);
            setMainModalVisible(false);
        }

        formik.resetForm();
    };

    const showModal = () => {
        setMainModalVisible(true);
        initialValues = getInitialValues(initialData, countriesList, filteredGamesList);
        formik.resetForm({ values: initialValues });
    };

    const handleSwitchChange = useCallback((checked: boolean) => {
        setIsFile(checked);
    }, []);

    const downloadCSVFile = () => {
        if (initialData?.csv) {
            const parsedCsvData: any = Papa.parse(initialData.csv, {
                header: false,
                skipEmptyLines: true,
            }).data;

            const cleanedCsvData =
                parsedCsvData.length > 0 && parsedCsvData[0][0] === 'Player id'
                    ? parsedCsvData.slice(1)
                    : parsedCsvData;

            const newCsvString = Papa.unparse(cleanedCsvData);
            const blob = new Blob([newCsvString], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, 'imported_list.csv');
        }
    };

    useEffect(() => {
        if (values.currency) {
            const uniqueBets = new Set<number>();

            gamesList.forEach((game: IGameItemData) => {
                const betSizesForCurrency = getBetSizes(
                    game,
                    currencyList.find(
                        (currency: ICurrencyData) => currency.code === values.currency,
                    ),
                );

                betSizesForCurrency.forEach((bet: number) => {
                    uniqueBets.add(bet);
                });
            });

            const sortedUniqueBets = Array.from(uniqueBets).sort((a: number, b: number) => a - b);

            setBetSizes(sortedUniqueBets);
            setFieldValue('bet', sortedUniqueBets[0]);
        }
    }, [values.currency, setFieldValue, gamesList, currencyList]);

    useEffect(() => {
        if (values.bet) {
            const filteredGames = gamesList.filter((game: IGameItemData) => {
                const betSizesForCurrency = getBetSizes(
                    game,
                    currencyList.find(
                        (currency: ICurrencyData) => currency.code === values.currency,
                    ),
                );

                return betSizesForCurrency.includes(values.bet);
            });

            setFilteredGamesList(filteredGames);
            setFieldValue('game_name', []);
        }
    }, [values.bet, currencyList, gamesList, setFieldValue, values.currency]);

    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        if (values.start_at && values.end_at) {
            const start = new Date(values.start_at);
            const end = new Date(values.end_at);
            const durationInMilliseconds = end.getTime() - start.getTime();
            const durationInMinutes = Math.floor(durationInMilliseconds / (1000 * 60));
            const durationInDays = Math.ceil(durationInMinutes / (60 * 24));
            if (durationInDays >= 0) {
                setFieldValue('expires_in_days', durationInDays);
            }
        }
    }, [values.start_at, values.end_at, setFieldValue]);

    useEffect(() => {
        if (betSizes.length > 0 && !isFirstRender) {
            if (!betSizes.includes(values.bet) && !isFirstRender) {
                setFieldValue('bet', betSizes[0]);
            }
        }
    }, [betSizes, values.bet, setFieldValue, isFirstRender]);

    useEffect(() => {
        if (mainModalVisible) {
            setIsFirstRender((prevIsFirstRender) => !prevIsFirstRender && prevIsFirstRender);
        }
    }, [mainModalVisible]);

    return (
        <div>
            <MainButton onClick={showModal}>
                {initialData ? (
                    <>
                        <EditOutlined />
                        {t('edit_campaign')}
                    </>
                ) : (
                    <>
                        <PlusCircleOutlined />
                        {t('add_new_promotion')}
                    </>
                )}
            </MainButton>
            <Modal
                destroyOnClose
                title={t('new_campaign_creation')}
                open={secondartModalVisible}
                footer={null}
                onCancel={handleCancelSecondary}
                className="new-campaign__modal"
            >
                <p>
                    The total cost of the free rounds campaign is {estimatedTotalBets()}. Are you
                    sure you want to launch
                </p>
                <div className="new-campaign-form-total__footer">
                    <SecondaryButton onClick={handleCancelSecondary}>{t('no')}</SecondaryButton>
                    <MainButton onClick={() => handleSubmit()}>{t('yes')}</MainButton>
                </div>
            </Modal>
            <Modal
                destroyOnClose
                width="90%"
                title={initialData ? t('campaign_editing') : t('new_campaign_creation')}
                open={mainModalVisible}
                onCancel={handleCancel}
                footer={null}
                className="new-campaign__modal"
            >
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                        <div style={{ display: 'flex' }}>
                            <div className="new-campaign_form-side">
                                <FormikField name="campaign_name" category="input" />
                                <FormikField name="promotion_type" category="select">
                                    <Select.Option key={'free_rounds'} value={'free_rounds'}>
                                        {t('free_rounds')}
                                    </Select.Option>
                                </FormikField>
                                <FormikField name="timezone" category="select">
                                    {timezoneList.map((timezone: ITimeZoneListItem) => (
                                        <Select.Option key={timezone.id} value={timezone.id}>
                                            {`UTC${timezone.offset} ${timezone.area}/${timezone.location}`.toUpperCase()}
                                        </Select.Option>
                                    ))}
                                </FormikField>
                                <FormikField
                                    name="start_at"
                                    category="datePicker"
                                    // defaultValue={initialData ? moment(initialData.startDate) : ''}
                                />
                                <FormikField
                                    name="end_at"
                                    category="datePicker"
                                    // defaultValue={initialData ? moment(initialData.endDate) : ''}
                                />
                                <FormikField
                                    name="expires_in_days"
                                    category="input"
                                    type="number"
                                />
                                <div className="new-campaign-form-item">
                                    <label
                                        className="new-campaign-form-item__label"
                                        htmlFor="strategy"
                                    >
                                        {t('players')}
                                    </label>
                                    <div className="new-campaign-form-item__field">
                                        <div className="new-campaign-form-item__with-upload">
                                            <Field
                                                disabled={isFile}
                                                id="players"
                                                name="players"
                                                type="number"
                                                placeholder={t('players_placeholder')}
                                                component={FormikInput}
                                            />
                                            <Field name="isFile">
                                                {({ field, form, meta }: any) => (
                                                    <FormikSwitch
                                                        id="isFile"
                                                        name="isFile"
                                                        meta={meta}
                                                        form={form}
                                                        field={field}
                                                        onChange={handleSwitchChange}
                                                    />
                                                )}
                                            </Field>
                                            <Field
                                                disabled={!isFile}
                                                name="file"
                                                component={FormikUpload}
                                            >
                                                <Button
                                                    disabled={!isFile}
                                                    icon={<UploadOutlined />}
                                                >
                                                    {t('upload_file')}
                                                </Button>
                                            </Field>
                                        </div>
                                        <ErrorMessage
                                            className="new-campaign-form-item__error"
                                            name="players"
                                            component="div"
                                        />
                                        <ErrorMessage
                                            className="new-campaign-form-item__error"
                                            name="file"
                                            component="div"
                                        />
                                    </div>
                                    {initialData?.csv && (
                                        <Button onClick={downloadCSVFile}>
                                            <DownloadOutlined />
                                        </Button>
                                    )}
                                </div>
                            </div>

                            <div style={{ flex: 1, paddingLeft: '1rem' }}>
                                <FormikField name="countries" category="select" mode="multiple">
                                    <Select.Option value="all">{t('all_countries')}</Select.Option>
                                    {countriesList.map((country: ICountry) => (
                                        <Select.Option key={country.name} value={country.name}>
                                            {country.name}
                                        </Select.Option>
                                    ))}
                                </FormikField>
                                <FormikField name="currency" category="select">
                                    {currencyList.map((currency: any) => (
                                        <Select.Option key={currency.code} value={currency.code}>
                                            {currency.code}
                                        </Select.Option>
                                    ))}
                                </FormikField>
                                <FormikField name="bet" category="select">
                                    {betSizes.map((bet: number) => (
                                        <Select.Option key={bet} value={bet}>
                                            {bet /
                                                currencyUtils.getCurrencyDataByCode(
                                                    formik.values.currency,
                                                ).subunit}
                                        </Select.Option>
                                    ))}
                                </FormikField>

                                <FormikField name="game_name" category="select" mode="multiple">
                                    <Select.Option value="all">{t('all_games')}</Select.Option>
                                    {filteredGamesList.map((game: { id: number; name: string }) => (
                                        <Select.Option key={game.name} value={game.name}>
                                            {game.name}
                                        </Select.Option>
                                    ))}
                                </FormikField>

                                <FormikField
                                    name="number_of_free_rounds"
                                    category="input"
                                    type="number"
                                />
                                <div className="new-campaign-form-total">
                                    <p className="new-campaign-form-total__item">
                                        {t('est_cost_per_player') + `: ${estimatedBetsPerPlayer()}`}
                                    </p>
                                    <p className="new-campaign-form-total__item">
                                        {t('est_total_bets') + `: ${estimatedTotalBets()}`}
                                    </p>
                                    <p className="new-campaign-form-total__item">
                                        {t('number_of_uploaded_players') +
                                            `: ${Number(formik.values.players)}`}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="new-campaign-form-total__footer">
                            <SecondaryButton onClick={() => handleCancel()}>
                                {t('close')}
                            </SecondaryButton>
                            <MainButton type="submit">
                                {initialData ? t('save_changes') : t('create_campaign')}
                            </MainButton>
                        </div>
                    </Form>
                </FormikProvider>
            </Modal>
            <Overlay
                isVisible={mainModalVisible || secondartModalVisible}
                switchState={setMainModalVisible}
            />
        </div>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    createPromotion: (data: any) => dispatch(createPromotion(data)),
    editPromotion: (data: any) => dispatch(editPromotion(data)),
    createPromotionWithCSV: (data: any) => dispatch(createPromotionWithCSV(data)),
    editPromotionWithCSV: (data: any) => dispatch(editPromotionWithCSV(data)),
    getPromotions: (data: any) => dispatch(getPromotions(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromotionModal);
