import React, { useCallback, useEffect, useState } from 'react';
import TableFilter from '../Filters/TableFilter';
import './TableHeaderWrapper.scss';
import { currencyUtils, dateFormatter, getLoginRequestHeader, localStorageHelper } from 'utils';
import Calendar from '../Calendar/Calendar';
import { useTranslation } from 'react-i18next';
import FilterMarks from 'Components/Filters/FilterMarks';
import { IFilters } from 'helpers/interfaces';
import DateAggregationWrapper from 'Containers/DateAggregatorWrapper/DateAggregatorWrapper';
import { Button, Tooltip } from 'antd';
import { SyncOutlined } from '@ant-design/icons/lib';
import { DownloadOutlined } from '@ant-design/icons';
import { SERVER_URL } from '../../utils/config';

const REPORT_KEY_TO_TYPE_MAP: Record<string, string> = {
    casino: 'player-group-revenue',
    games: 'game-revenue',
    players: 'player-revenue',
};

interface IProps {
    getReport: Function;
    parentReportCallback: Function;
    fromDate: Date;
    toDate: Date;
    reportKey: string;
    titleComponent?: JSX.Element;
    isShowSelectTime?: boolean;
    isShowDateAggregation?: boolean;
    notShowFilters?: boolean;
    context: any;
    currenciesBreakdown?: any;
}

const defaultFiltersValues: IFilters = {
    currencies: localStorageHelper.getChainedValue('user.reports.filters.currencies') || [],
    currenciesBreakdown:
        localStorageHelper.getChainedValue('user.reports.filters.currenciesBreakdown') || false,
    deviceType: localStorageHelper.getChainedValue('user.reports.filters.deviceType') || 'all',
    games: localStorageHelper.getChainedValue('user.reports.filters.games') || [],
};

const TableHeaderWrapper: React.FC<IProps> = ({
    getReport,
    parentReportCallback,
    fromDate,
    toDate,
    reportKey,
    titleComponent,
    isShowSelectTime,
    isShowDateAggregation,
    notShowFilters,
    context,
}) => {
    const { t } = useTranslation();
    const [applyingFilters, setApplyingFilters] = useState<IFilters>(defaultFiltersValues);
    const [appliedFilters, setAppliedFilters] = useState<IFilters>(defaultFiltersValues);

    const applyFilters = useCallback(() => {
        localStorageHelper.setChainedValue('user.reports.filters', applyingFilters);
        setAppliedFilters(applyingFilters);
        getReport();
    }, [applyingFilters, getReport]);

    const cancelFilters = () => {
        setApplyingFilters({
            currencies: localStorageHelper.getChainedValue('user.reports.filters.currencies') || [],
            currenciesBreakdown:
                localStorageHelper.getChainedValue('user.reports.filters.currenciesBreakdown') ||
                false,
            deviceType:
                localStorageHelper.getChainedValue('user.reports.filters.deviceType') || 'all',
            games: localStorageHelper.getChainedValue('user.reports.filters.games') || [],
        });
    };

    const resetFilters = () => {
        localStorageHelper.setChainedValue('user.reports.filters', {
            currencies: [],
            currenciesBreakdown: false,
            deviceType: 'all',
            games: [],
        });

        setApplyingFilters((prev) => ({
            ...prev,
            currencies: [],
            deviceType: 'all',
            games: [],
        }));
    };

    const onFiltersChangeCallback = (
        changedField: keyof IFilters,
        value: any,
        isToApplyFilters = false,
    ) => {
        const filters: IFilters = { ...applyingFilters };

        if (changedField === 'currencies' && Array.isArray(value)) {
            filters.currencies = value;
        } else if (changedField === 'currenciesBreakdown' && typeof value === 'boolean') {
            filters.currenciesBreakdown = value;
        } else if (changedField === 'deviceType' && typeof value === 'string') {
            filters.deviceType = value;
        } else if (changedField === 'games' && Array.isArray(value)) {
            filters.games = value;
        } else {
            // Handle error: Invalid type for the given key
            return;
        }

        setApplyingFilters(filters);
        if (isToApplyFilters) applyFilters();
    };

    const updateReport = () => {
        getReport();
    };

    const handleDownloadReport = () => {
        const requestBody = {
            reportType: REPORT_KEY_TO_TYPE_MAP[reportKey],
            includeTestPlayers:
                localStorageHelper.getChainedValue('user.preferences.displayTestData') || false,
            playerCurrencyIds: currencyUtils.getSelectedCurrenciesIdsData(),
            start: dateFormatter.getFormattedStartDate(fromDate),
            end: dateFormatter.getFormattedEndDate(toDate),
            timeBreakdown:
                localStorageHelper.getChainedValue('user.reports.aggregationPeriod') || 'day',
            currencyBreakdown: localStorageHelper.getChainedValue(
                'user.reports.filters.currenciesBreakdown',
            )
                ? 'currency'
                : 'none',
        };

        const config = {
            method: 'POST',
            headers: {
                ...getLoginRequestHeader('application/json'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        };

        fetch(`${SERVER_URL}/reports/revenue/csv-export`, config).then((response) => {
            response.blob().then((blob) => {
                if (response.status === 200) {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'report.csv';
                    a.click();
                }
            });
        });
    };

    useEffect(() => {
        const filters = localStorageHelper.getChainedValue('user.reports.filters');
        if (filters) {
            if (reportKey === 'playerProfile') {
                filters.currencies = [];
                filters.currenciesBreakdown = false;
                filters.deviceType = 'all';
            } else {
                filters.games = [];
            }
            setApplyingFilters(filters);
            setAppliedFilters(filters);
        }
    }, [reportKey]);

    useEffect(() => {
        return () => {
            resetFilters();
        };
    }, []);

    const isReportDownloadable = !!REPORT_KEY_TO_TYPE_MAP[reportKey];

    return (
        <div className="table-tools">
            <div className="header-line">
                {titleComponent}
                <div className="tools">
                    <Tooltip title={t('refresh')}>
                        <Button style={{ marginRight: '8px' }} onClick={updateReport}>
                            <SyncOutlined />
                        </Button>
                    </Tooltip>
                    {isShowDateAggregation ? (
                        <DateAggregationWrapper
                            parentReportCallback={parentReportCallback}
                            isShowSelectTime={isShowSelectTime}
                            getReport={getReport}
                            dates={{ fromDate, toDate }}
                            context={context}
                        />
                    ) : (
                        <Calendar
                            parentReportCallback={parentReportCallback}
                            isShowSelectTime={isShowSelectTime}
                            defaultValue={{ fromDate, toDate }}
                            context={context}
                        />
                    )}
                    {isReportDownloadable && (
                        <Button style={{ marginRight: '8px' }} onClick={handleDownloadReport}>
                            <DownloadOutlined />
                            {t('export_to_csv')}
                        </Button>
                    )}
                    {!notShowFilters && (
                        <TableFilter
                            filters={applyingFilters}
                            applyFilters={applyFilters}
                            onFiltersChangeCallback={onFiltersChangeCallback}
                            cancelFilters={cancelFilters}
                            reportKey={reportKey}
                            context={context}
                        />
                    )}
                </div>
            </div>
            <FilterMarks
                filters={appliedFilters}
                onReset={resetFilters}
                onFiltersChangeCallback={onFiltersChangeCallback}
            />
        </div>
    );
};

export default TableHeaderWrapper;
