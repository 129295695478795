import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as i18next from 'i18next';
import { Dispatch } from 'redux';
import { BRAND_STEPS } from 'ConfigurationProvider/ManageBrands/AddBrand';
import { currencyUtils } from 'utils';
import { UpOutlined } from '@ant-design/icons/lib';
import { Input } from 'antd';
import {
    getBrandConfigsRequest,
    updateBrandConfigsRequest,
} from 'redux/actions/configProvider/manage-brand-actions';
import { Loader } from 'Components/Loader/Loader';
import { Footer } from 'Components/Footer/Footer';
import { MainButton, SecondaryButton } from 'Components/CustomButtons/CustomButtons';
import { IBrandCurrencyData, IBrandData, IOperatorData } from 'helpers/interfaces';
import { IGameCurrencyConfig } from 'helpers/interfaces';
import {
    getBrandConfigs,
    isBrandDataLoading,
} from 'redux/selectors/configProvider/manage-brand-selectors';
import { ChangeEvent } from 'react';
import { withRouter } from '../../../helpers/HOCs/withRouter';

const { TextArea } = Input;

interface ICustomConfig {
    currencyId: number;
    isCollapsed: boolean;
    isValid: boolean;
    gameConfig?: any;
}

interface IProps {
    operatorData: IOperatorData;
    brandData: IBrandData;
    getBrandConfigs: Function;
    updateBrandConfigs: Function;
    configs: IGameCurrencyConfig[];
    loading: boolean;
    previousStep: Function;
    t: i18next.TFunction;
    navigate: any;
}

interface IState {
    configurations: ICustomConfig[];
}

class StepManageBrandConfigurations extends React.Component<IProps & WithTranslation, IState> {
    state: IState = {
        configurations: [],
    };

    getConfigs = () => {
        const { configurations } = this.state;

        return configurations
            .map((configuration: ICustomConfig, index: number) => {
                const { currencyId, gameConfig, isCollapsed, isValid } = configuration;
                const { code, id, name } = currencyUtils.getCurrencyDataById(Number(currencyId));

                return (
                    <div className="currency-item" key={code}>
                        <div className="arrow">
                            <UpOutlined rotate={isCollapsed ? 180 : 0} />
                        </div>
                        <h3 onClick={(e) => this.handleCollapsing(e, index)}>
                            {code} - {name} <span className="gray">{id}</span>
                        </h3>
                        <div className={`currency-content ${isCollapsed ? ' collapsed' : ''}`}>
                            <TextArea
                                defaultValue={gameConfig}
                                rows={10}
                                onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                                    this.onChange(e, index)
                                }
                            />
                            <p className="error">{!isValid && this.props.t('json_not_valid')}</p>
                        </div>
                    </div>
                );
            })
            .sort((a: any, b: any) => (a.key > b.key ? 1 : b.key > a.key ? -1 : 0));
    };

    handleCollapsing = (e: any, index: number) => {
        const { configurations } = this.state;

        configurations[index].isCollapsed = !configurations[index].isCollapsed;

        this.setState({ configurations });
    };

    onChange = (e: ChangeEvent<HTMLTextAreaElement>, index: number) => {
        const { configurations } = this.state;
        const { value } = e.target;

        configurations[index].gameConfig = value;
        configurations[index].isValid = true;

        this.setState({ configurations });
    };

    expandAll = () => {
        const { configurations } = this.state;

        configurations.forEach(
            (config: ICustomConfig, index: number) => (configurations[index].isCollapsed = false),
        );

        this.setState({ configurations });
    };

    collapseAll = () => {
        const { configurations } = this.state;

        configurations.forEach(
            (config: ICustomConfig, index: number) => (configurations[index].isCollapsed = true),
        );

        this.setState({ configurations });
    };

    previousStep = () => {
        this.props.previousStep();
        this.setState({ configurations: [] });
    };

    handleSubmit = () => {
        const { brandData } = this.props;
        const { configurations } = this.state;
        const configs: any = {};
        let isValid = true;

        configurations.forEach((configuration: ICustomConfig, index: number) => {
            if (configuration.gameConfig) {
                try {
                    const parsedConfig = JSON.parse(`{${configuration.gameConfig}}`);

                    for (const key in parsedConfig) {
                        if (Object.prototype.hasOwnProperty.call(parsedConfig, key)) {
                            configs[key] = JSON.stringify(parsedConfig[key]);
                        }
                    }
                } catch (e) {
                    configurations[index].isValid = false;
                    isValid = false;
                }
            }
        });

        this.setState({ configurations });

        if (isValid) {
            this.props.updateBrandConfigs({
                brandId: brandData.id,
                gameConfig: this.getGameConfigToSend(),
            });
        }
    };

    getGameConfigToSend = () => {
        const { configurations } = this.state;
        const configToSend: any = {};

        configurations.forEach((configuration: ICustomConfig) => {
            const { gameConfig } = configuration;

            if (gameConfig) {
                const config = JSON.parse(`{${gameConfig}}`);

                for (const key in config) {
                    if (
                        Object.prototype.hasOwnProperty.call(config, key) &&
                        typeof config[key] === 'object' &&
                        config[key] !== null
                    ) {
                        configToSend[key] = JSON.stringify(config[key]);
                    }
                }
            }
        });

        return configToSend;
    };

    finish = () => this.props.navigate(`/config-provider/operators`);

    componentDidUpdate = () => {
        const { loading, brandData, configs } = this.props;

        if (!loading && configs && configs?.length && !this.state.configurations.length) {
            const configurations: ICustomConfig[] = [];
            const getRelevantConfig = (currencyId: number) =>
                configurations.filter(
                    (config: ICustomConfig) => config.currencyId === currencyId,
                )[0];

            brandData.currencies.forEach((currency: IBrandCurrencyData) =>
                configurations.push({
                    currencyId: currency.currencyId,
                    gameConfig: '',
                    isCollapsed: true,
                    isValid: true,
                }),
            );

            configs.forEach((config: IGameCurrencyConfig) => {
                const { gameConfig, currencyId, gameId } = config;
                const relevantConfig = getRelevantConfig(currencyId);
                const idMultiplier = 10000;
                const configId = gameId * idMultiplier + currencyId;

                if (gameConfig && relevantConfig) {
                    relevantConfig.gameConfig += `${
                        relevantConfig.gameConfig.length > 0 ? ',\n' : ''
                    }"${configId}": ${gameConfig}`;
                    relevantConfig.isCollapsed = false;
                }
            });

            configurations.forEach((config: ICustomConfig, index: number) => {
                if (config.gameConfig) {
                    configurations[index].gameConfig = `${config.gameConfig}`;
                }
            });

            this.setState({ configurations });
        }
    };

    componentDidMount = () => {
        const { brandData } = this.props;
        const { configurations } = this.state;

        if (!configurations?.length) {
            this.props.getBrandConfigs(brandData.id);
        }
    };

    render = () => {
        const { t, loading } = this.props;

        return (
            <>
                <div className="operator-step">
                    {loading ? (
                        <Loader />
                    ) : (
                        <>
                            <h2 className="title with-paddings">{t(BRAND_STEPS[3])}</h2>
                            <div className="buttons without-paddings">
                                <div className="btn white" onClick={this.expandAll}>
                                    {t('expand_all')}
                                </div>
                                <div className="btn white" onClick={this.collapseAll}>
                                    {t('collapse_all')}
                                </div>
                            </div>
                            {this.getConfigs()}
                        </>
                    )}
                </div>
                <Footer className="bottom-buttons">
                    <SecondaryButton onClick={this.previousStep}>{t('back')}</SecondaryButton>
                    <SecondaryButton onClick={this.finish}>{t('finish')}</SecondaryButton>
                    <MainButton onClick={this.handleSubmit}>{t('continue_save')}</MainButton>
                </Footer>
            </>
        );
    };
}

const mapStateToProps = (state: any) => ({
    loading: isBrandDataLoading(state),
    configs: getBrandConfigs(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getBrandConfigs: (data: any) => dispatch(getBrandConfigsRequest(data)),
    updateBrandConfigs: (data: any) => dispatch(updateBrandConfigsRequest(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(withRouter(StepManageBrandConfigurations)));
